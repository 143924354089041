import React, { useEffect, useState } from "react";

import {
  Tooltip,
  TextField,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { DeleteOutline, Add } from "@material-ui/icons";
import useStyles from "./styles";

import Table from "./components/Table/Table";
import SavedModal from "./components/SavedModal";
import GithaxsSDK from "../../GithaxsSDK";
import TaskConfig from "../../context/models/TaskConfig";
import OrgParameters from "./components/OrgParameters";
import { getCurrentOrg } from "../../utils/functions";

function TaskConfigDialog(props) {
  const org = new URLSearchParams(props.history.location.search).get("org");
  const fullOrg = getCurrentOrg(org);
  const gh = new GithaxsSDK();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [config, setConfig] = useState();

  const isUserAdmin = fullOrg.admin === true;
  const selectedTask = props.location.state;
  var currentTask = selectedTask.slug;

  useEffect(() => {
    const getTaskConfig = async function getTaskConfig() {
      var resp = await gh.get(`configurations/${org}/${currentTask}`);
      var config = resp.data;

      if (!config.settings) {
        config.settings = {
          org: {}
        }
      }
      setConfig(config);
    };

    getTaskConfig();
  }, [org, currentTask]);

  async function saveConfig() {
    await gh.post(`configurations/${org}/${currentTask}`, config);

    setModalOpen(true);
  }

  if (!config) {
    return "";
  }

  const taskConfig = TaskConfig.fromJson(config);

  const addRepoInstallation = () => {
    taskConfig.installation.repo_names.addInstallation("Repo Name");
    setConfig(taskConfig.toJson());
  };

  const addRepoTopicInstallation = () => {
    taskConfig.installation.repo_topics.addInstallation("Repo Topic");
    setConfig(taskConfig.toJson());
  };

  const addRepoLanguageInstallation = () => {
    taskConfig.installation.repo_languages.addInstallation("Repo Language");
    setConfig(taskConfig.toJson());
  };

  const updateInstallation = (key) => {
    return function (value, index) {
      const oldValue = taskConfig.installation[key].toJSON()[index];
      taskConfig.installation[key].updateInstallation(oldValue, value);
      setConfig(taskConfig.toJson());
    };
  };
  const deleteInstallation = (key) => {
    return function (value) {
      taskConfig.installation[key].removeInstallation(value);
      setConfig(taskConfig.toJson());
    };
  };

  const updateOrgInstallation = () => {
    taskConfig.setOrgInstallation(!taskConfig.installation.org);
    setConfig(taskConfig.toJson());
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  function renderRepoTopicParameters() {
    return ((config.settings && config.settings.repo_topics) || []).map(
      (repo_topic, idx) => {
        const parameters = selectedTask.parameters.map((p, idx) => {
          function updateValue(value) {
            repo_topic[p.name] = value;
          }

          let currentParameterValue = repo_topic[p.name];
          return (
            <Grid item xs={9}>
              <Tooltip title={p.description}>
                <Box px={4}>
                  <TextField
                    key={idx}
                    margin="dense"
                    id="outlined-basic"
                    defaultValue={currentParameterValue || p.default}
                    label={p.name}
                    type="text"
                    fullWidth
                    onChange={(e) => {
                      updateValue(e.target.value);
                    }}
                    variant="outlined"
                  />
                </Box>
              </Tooltip>
            </Grid>
          );
        });

        function deleteItem() {
          taskConfig.settings.repo_topics.splice(idx, 1);
          setConfig(taskConfig.toJson());
        }

        function updateRepoTopicName(value) {
          repo_topic.name = value;
        }
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box px={4}>
                <TextField
                  key={idx + 30}
                  margin="dense"
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={repo_topic.name}
                  label="Repo Topic"
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    updateRepoTopicName(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={8} justify="flex-start">
              <DeleteOutline onClick={() => deleteItem()} />
            </Grid>
            {parameters}
          </Grid>
        );
      },
    );
  }

  function renderRepoParameters() {
    return ((config.settings && config.settings.repos) || []).map(
      (repo, idx) => {
        const parameters = selectedTask.parameters.map((p, idx) => {
          function updateValue(value) {
            repo[p.name] = value;
          }

          let currentParameterValue = repo[p.name];
          return (
            <Grid item xs={9}>
              <Tooltip title={p.description}>
                <Box px={4}>
                  <TextField
                    key={idx}
                    margin="dense"
                    id="outlined-basic"
                    defaultValue={currentParameterValue || p.default}
                    label={p.name}
                    type="text"
                    fullWidth
                    onChange={(e) => {
                      updateValue(e.target.value);
                    }}
                    variant="outlined"
                  />
                </Box>
              </Tooltip>
            </Grid>
          );
        });

        function deleteItem() {
          taskConfig.settings.repos.splice(idx, 1);
          setConfig(taskConfig.toJson());
        }

        function updateRepoName(value) {
          repo.name = value;
        }
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box px={4}>
                <TextField
                  key={idx + 30}
                  margin="dense"
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={repo.name}
                  label="Repo Name"
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    updateRepoName(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={8} justify="flex-start">
              <DeleteOutline onClick={() => deleteItem()} />
            </Grid>
            {parameters}
          </Grid>
        );
      },
    );
  }

  const updateOrgFinalConfig = (key, value) => {
    taskConfig.settings.org.final[key] = value;
    setConfig(taskConfig.toJson());
  };

  const updateOrgDefaultConfig = (key, value) => {
    taskConfig.settings.org.default[key] = value;
    setConfig(taskConfig.toJson());
  };

  function addRepoTopicSetting() {
    const repoTopicSetting = { name: "Repo Topic", priority: 10 };
    selectedTask.parameters.forEach((p) => {
      repoTopicSetting[p.name] = p.default;
    });

    taskConfig.settings.repo_topics.push(repoTopicSetting);
    setConfig(taskConfig.toJson());
  }

  function addRepoSetting() {
    const repoSetting = { name: "Repo Name" };
    selectedTask.parameters.forEach((p) => {
      repoSetting[p.name] = p.default;
    });
    taskConfig.settings.repos.push(repoSetting);
    setConfig(taskConfig.toJson());
  }

  return (
    <div className={classes.container}>
      <SavedModal open={modalOpen} handleModalClose={handleModalClose} />
      <h1 className={classes.headerFont}>{selectedTask.name}</h1>
      <p dangerouslySetInnerHTML={{ __html: selectedTask.description }} />
      <Grid container spacing={4} className={classes.boxShadow}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.otherFont}>
            Installation
          </Typography>
          <p>Configure which repositories to run this task on.</p>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" className={classes.otherFont}>
            All Repos
          </Typography>
          <p>
            Run this task on every repo the Githaxs GitHub Application is
            installed on.
          </p>
          <p>
            If you installed Githaxs on every repository in your organization
            then this task will run on every repo. Otherwise, it will run on the
            repos you selected during installation.
          </p>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={config.installation.org}
                onClick={() => updateOrgInstallation()}
              />
            }
          />
        </Grid>
        {!config.installation.org && (
          <>
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.otherFont}>
                Repos
              </Typography>
              <p>Only run this task on the following selected repos.</p>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.verticalFlexbox}>
                <Table
                  data={config.installation.repo_names}
                  addItem={addRepoInstallation}
                  updateItem={updateInstallation("repo_names")}
                  deleteItem={deleteInstallation("repo_names")}
                />
              </div>
            </Grid>
          </>
        )}
        {!config.installation.org && (
          <>
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.otherFont}>
                Repo Topics
              </Typography>
              <p>
                Use{" "}
                <a href="https://docs.github.com/en/repositories/managing-your-repositorys-settings-and-features/customizing-your-repository/classifying-your-repository-with-topics">
                  Repo Topics{" "}
                </a>
                to run this task on groups of repositories.
              </p>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.verticalFlexbox}>
                <Table
                  data={config.installation.repo_topics}
                  addItem={addRepoTopicInstallation}
                  updateItem={updateInstallation("repo_topics")}
                  deleteItem={deleteInstallation("repo_topics")}
                />
              </div>
            </Grid>
          </>
        )}
        {!config.installation.org && (
          <>
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.otherFont}>
                Repo Languages
              </Typography>
              <p>
                Run this task on repos that have the selected programming
                languages as determine by{" "}
                <a href="https://docs.github.com/en/repositories/managing-your-repositorys-settings-and-features/customizing-your-repository/about-repository-languages">
                  GitHub
                </a>
              </p>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.verticalFlexbox}>
                <Table
                  data={config.installation.repo_languages}
                  addItem={addRepoLanguageInstallation}
                  updateItem={updateInstallation("repo_languages")}
                  deleteItem={deleteInstallation("repo_languages")}
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>
      {selectedTask.parameters.length > 0 && (
        <Grid container spacing={4} className={classes.boxShadow}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.otherFont}>
              Settings
            </Typography>
            <p>
              Githaxs provides several different ways to configure tasks to
              provide maximum flexibility.
            </p>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Final</Typography>
            <p>
              Cannot be overridden and will take precedence over any other
              settings. Use this option if you want to provide centralized
              governance to enforce consistent standards across your entire
              organization.
            </p>
          </Grid>
          <Grid item xs={6}>
            <OrgParameters
              config={config.settings.org.final}
              selectedTask={selectedTask}
              update={updateOrgFinalConfig}
            ></OrgParameters>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Repo</Typography>
            <p>
              Configure settings for specific repos. Unless Final Settings have
              been specified, repo specific settings will be used.
            </p>
          </Grid>
          <Grid item xs={6}>
            {renderRepoParameters()}
            <Add onClick={() => addRepoSetting()} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Repo Topics</Typography>
            <p>
              Use{" "}
              <a href="https://docs.github.com/en/repositories/managing-your-repositorys-settings-and-features/customizing-your-repository/classifying-your-repository-with-topics">
                Repo Topics{" "}
              </a>
              to classify and apply settings to groups of repos. Using this
              method you can apply one setting to a group of repos with one
              label and a different different settings to a group of repos with
              a different label.
            </p>
          </Grid>
          <Grid item xs={6}>
            {renderRepoTopicParameters()}
            <Add onClick={() => addRepoTopicSetting()} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Default</Typography>
            <p>
              If no other settings have been configured the default settings
              will apply.
            </p>
          </Grid>
          <Grid item xs={6}>
            <OrgParameters
              config={config.settings.org.default}
              selectedTask={selectedTask}
              update={updateOrgDefaultConfig}
            ></OrgParameters>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          disabled={!isUserAdmin}
          onClick={saveConfig}
        >
          Save
        </Button>
      </Grid>
    </div>
  );
}

export default TaskConfigDialog;
