import React, { useState, useEffect } from "react";

import { CreateOutlined, LockOutlined } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import TaskTable from "./components/TaskTable/TaskTable";

import GithaxsSDK from "../../GithaxsSDK";
import { getCurrentOrg } from "../../utils/functions";

export default function Tables(props) {
  const { search } = useLocation();
  const org = new URLSearchParams(search).get("org");
  const fullOrg = getCurrentOrg(org);

  const [tableData, setTableData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [sendAlert, setSendAlert] = useState(false);

  const gh = new GithaxsSDK();

  useEffect(() => {
    const getTasks = async function getTasks() {
      var r = await gh.get(`tasks?org_name=${org}`);
      var tasks = r.data;
      setTasks(tasks);
    };

    getTasks();
  }, [org]);

  useEffect(() => {
    let td = tasks.map((task) => {
      return {
        name: task.name,
        slug: task.slug,
        summary: task.summary,
        enabled: false,
        hasSettings: true,
        beta: `${task.beta}`,
        subscription_level: task.subscription_level,
      };
    });

    setTableData(td);
    // eslint-disable-next-line
  }, [tasks]);

  const handleClickOpen = (index) => {
    let selectedTask = tasks[index];

    props.history.push({
      pathname: `/app/tasks/${selectedTask.slug}`,
      search: search,
      state: selectedTask,
    });
  };

  const handleBlockClick = () => {
    setSendAlert(true);
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
      },
    },
    {
      name: "summary",
      label: "Summary",
      options: {
        filter: false,
      },
    },
    {
      name: "hasSettings",
      label: "Edit Settings",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          if (
            fullOrg.subscription_level >=
            tableMeta.tableData[tableMeta.rowIndex].subscription_level
          ) {
            let element = value ? (
              <Grid item xs={12} container justify={"center"}>
                <CreateOutlined
                  onClick={() => handleClickOpen(tableMeta.rowIndex)}
                />
              </Grid>
            ) : (
              ""
            );
            return element;
          } else {
            let element = value ? (
              <Grid item xs={12} container justify={"center"}>
                <LockOutlined onClick={() => handleBlockClick()} />
              </Grid>
            ) : (
              ""
            );
            return element;
          }
        },
      },
    },
  ];

  return (
    <>
      {sendAlert ? (
        <Alert
          severity="error"
          sx={{ marginBottom: "10px" }}
          onClose={() => setSendAlert(false)}
        >
          Upgrade your subscription to use this task.
        </Alert>
      ) : null}
      <TaskTable tableData={tableData} columns={columns} />
    </>
  );
}
