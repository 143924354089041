import {
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { CreateOutlined } from "@material-ui/icons";
import Widget from "../../../components/Widget/Widget";

export default function NestedContext({ context, contextName }) {
    function handleClick(key) {
        console.log(key)
    }

    const widgets = Object.keys(context || {}).map((topicName, idx) => {
        return Object.keys(context[topicName]).map((key, idx) => {
            return (
                <Grid item xs={12}>
                <Widget
                    key={idx}
                    title={`${contextName} / ${topicName}`}
                    upperTitle
                    disableWidgetMenu={true}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                {key}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">
                                {context[topicName][key]}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CreateOutlined
                                onClick={() => handleClick(key)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="info"
                        //disabled={!isUserAdmin}
                        //onClick={saveConfig}
                    >
                        Add Value
                    </Button>
                </Grid>
                </Widget>
                
                </Grid>
            )
        })
    })

    return (
        <>
        {widgets}
        <Button
                        variant="contained"
                        color="primary"
                        //disabled={!isUserAdmin}
                        //onClick={saveConfig}
                    >
                        Create Context
                    </Button>

        </>
    )
}