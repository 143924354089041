import { TextField, Box, Tooltip } from "@material-ui/core";

export default function OrgParameters(props) {
  const { selectedTask, config, update } = props;
  return selectedTask.parameters.map((p, idx) => {
    let currentParameterValue;
    try {
      currentParameterValue = config[p.name];
    } catch (e) {
      //console.log("Parameter does not have a saved value")
    }
    return (
      <Tooltip title={p.description}>
        <Box px={4}>
          <TextField
            key={idx}
            margin="dense"
            id="outlined-basic"
            defaultValue={currentParameterValue || p.default}
            label={p.name}
            type="text"
            fullWidth
            onChange={(e) => {
              update(p.name, e.target.value);
            }}
            variant="outlined"
          />
        </Box>
      </Tooltip>
    );
  });
}
