import React from "react";
import { Grid } from "@material-ui/core";
import { Paper, Typography, Tooltip } from "@material-ui/core";
import classnames from "classnames";
import { HelpOutline } from "@material-ui/icons";

// styles
import useStyles from "./styles.js";

export default function Widget({
  children,
  title,
  noBodyPadding,
  bodyClass,
  header,
  tooltip,
  icon,
  noHeaderPadding,
  headerClass,
  style,
  noWidgetShadow,
  ...props
}) {
  var classes = useStyles();

  return (
    <div className={classes.widgetWrapper} style={style && { ...style }}>
      <Paper
        className={classes.paper}
        classes={{
          root: classnames(classes.widgetRoot, {
            [classes.noWidgetShadow]: noWidgetShadow,
          }),
        }}
      >
        <div
          className={classnames(classes.widgetHeader, {
            [classes.noPadding]: noHeaderPadding,
            [headerClass]: headerClass,
          })}
        >
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h5" color="textSecondary" noWrap>
                {title}
              </Typography>
              <Tooltip title={tooltip}>
                <HelpOutline></HelpOutline>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          <Grid container>
            <Grid item xs={6}>
              {children}
            </Grid>
            <Grid item xs={6} style={{ padding: "50px" }}>
              {icon}
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}
