import React from "react";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("jwt"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, github_token, user, user_orgs, history) {
  if (!!github_token) {
    setTimeout(() => {
      localStorage.setItem("jwt", github_token);
      localStorage.setItem("user", user.login);
      localStorage.setItem("user_data", JSON.stringify(user));
      localStorage.setItem("user_avatar", user.avatar_url);
      localStorage.setItem("user_orgs", JSON.stringify(user_orgs));
      localStorage.setItem("current_org", JSON.stringify(user_orgs[0]));
      dispatch({ type: "LOGIN_SUCCESS" });
      history.push(`/app/tasks?org=${user_orgs[0].login}`);
    }, 20);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  localStorage.removeItem("user_data");
  localStorage.removeItem("user_avatar");
  localStorage.removeItem("user_orgs");
  localStorage.removeItem("current_org");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
