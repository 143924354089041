import React from "react";
import ReactApexChart from "react-apexcharts";

export default function ApexBubbleChart({ series, xaxis_type = "datetime" }) {
  const state = {
    series: series,
    options: {
      chart: {
        height: 550,
        type: "bubble",
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        z: {
          title: "File Changes",
          formatter: () => "NA",
        },
      },
      fill: {
        opacity: 0.8,
      },
      title: {
        text: "Tech Debt Analysis",
      },
      xaxis: {
        tickAmount: 5,
        title: {
          text: "Complexity",
        },
      },
      yaxis: {
        max: 100,
        decimalsInFloat: 0,
        title: {
          text: "Test Coverage",
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={series}
        type="bubble"
        height={550}
      />
    </div>
  );
}
