import React, { useState, useEffect } from "react";

import GithaxsSDK from "../../../GithaxsSDK";
import ApexBubbleChart from "../../../components/ApexBubbleChart/ApexBubbleChart";

export default function Analytics({ org, repo, loading }) {
  const [series, setSeries] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    async function getHalsteadMetrics() {
      const gh = GithaxsSDK.build();

      let halstead_data = [];
      let coverage_data = [];
      try {
        halstead_data = await gh.getHalsteadMetrics(org, repo);
        coverage_data = await gh.getCoverage(org, repo);
        //const changed_files = await gh.getFileChanges(layoutState.currentOrg.login)
      } catch (e) {
        console.log(e);
        setShow(false);
      }

      const files = {};

      halstead_data.forEach((h) => {
        files[h["name"]] = [[h["halstead_metrics"]["difficulty"], 0, 5]];
      });

      coverage_data.forEach((c) => {
        if (files[c["name"]]) {
          files[c["name"]][0][1] = c["coverage_data"]["percent_covered"];
        } else {
          files[c["name"]] = [[0, c["coverage_data"]["percent_covered"], 5]];
        }
      });

      /*
      changed_files.forEach( c => {
        if(files[c['name']]) {
          files[c['name']][0][0] = c['changes']
        } else {
          files[c['name']] = [[c['changes'],0 ,0]]
        }
      })
      */

      const graph_series = [];
      for (const file in files) {
        if (files[file][0][2] > 0) {
          graph_series.push({ name: file, data: files[file] });
        }
      }
      setSeries(graph_series);
    }

    if (repo !== "All") {
      getHalsteadMetrics();
    }
    // eslint-disable-next-line
  }, [org, repo]);
  return <>{show && <ApexBubbleChart series={series}></ApexBubbleChart>}</>;
}
