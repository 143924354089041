import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

export default function ApexLineChart({ series, xaxis_categories, title }) {
  var theme = useTheme();

  const options = themeOptions(theme);
  options.xaxis.categories = xaxis_categories;
  options.title = {
    text: title,
  };
  return (
    <ApexCharts options={options} series={series} type="area" height={350} />
  );
}

// ############################################################
function themeOptions(theme) {
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [], // set by props
    },
    tooltip: {
      x: {
        format: "MM/dd/yy",
      },
      y: {
        format: "days",
      },
    },
    fill: {
      colors: [
        theme.palette.primary.light,
        theme.palette.success.light,
        theme.palette.warning.light,
      ],
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
    ],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };
}
