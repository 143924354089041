import {
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { CreateOutlined } from "@material-ui/icons";
import Widget from "../../../components/Widget/Widget";

export default function FinalContext({ context }) {
    function handleClick(key) {
        console.log(key)
    }

    return Object.keys(context || []).map((key, idx) => {
        return (
            <Widget
                key={idx}
                title="Final"
                upperTitle
                disableWidgetMenu={true}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            {key}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">
                            {context[key]}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <CreateOutlined
                            onClick={() => handleClick(key)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="info"
                        //disabled={!isUserAdmin}
                        //onClick={saveConfig}
                    >
                        Add Value
                    </Button>
                </Grid>
            </Widget>
        )
    })
}