import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

function TaskTable({ tableData, columns }) {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="PR Log"
            data={tableData}
            columns={columns}
            options={{
              filterType: "checkbox",
              download: false,
              print: false,
              selectableRows: "none",
              selectableRowsHideCheckboxes: true,
              selectableRowsOnClick: true,
              selectToolbarPlacement: "none",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default TaskTable;
