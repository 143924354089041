import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Timeline as TimelineIcon,
  ArrowBack as ArrowBackIcon,
  Settings as SettingsIcon,
  CreditCard,
  Notes,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter, useLocation } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import { getCurrentOrg } from "../../utils/functions";

function Sidebar({ location, history }) {
  const { search } = useLocation();
  const org = new URLSearchParams(search).get("org");
  const fullOrg = getCurrentOrg(org);
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  const structure = [
    {
      id: 0,
      label: "Tasks",
      link: `/app/tasks${search}`,
      icon: <SettingsIcon />,
    },
    {
      id: 1,
      label: "Insights",
      link: `/app/insights${search}`,
      icon: <TimelineIcon />,
      children: [
        { label: "Pull Requests", link: "/app/insights/pull-requests" },
        { label: "Check Runs", link: "/app/insights/check-runs" },
        { label: "Status Checks", link: "/app/insights/status-checks" },
      ],
    },
    {
      id: 2,
      label: "PR Log",
      link: `/app/prlog${search}`,
      icon: <Notes />,
    },
    /*
    {
      id: 2,
      label: "Contexts",
      link: "/app/contexts",
      icon: <DonutLarge />
    },
    {
      id: 3,
      label: "Projects",
      link: "/app/projects",
      icon: <Extension />
    },
    {
      id: 3,
      label: "Deployments",
      link: "/app/deployments",
      icon: <Launch />
    },
    */
    {
      id: 4,
      label: "Account Management",
      link: `/app/account${search}`,
      icon: <CreditCard />,
    },
  ];

  var userState = useUserState();
  var { isAuthenticated } = userState;
  var isAdmin = isAuthenticated && fullOrg.admin === true;
  var isGithaxsAdmin =
    isAuthenticated && org === "githaxs" && fullOrg.admin === true;

  useEffect(
    function () {
      function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
          setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
          setPermanent(true);
        }
      }

      window.addEventListener("resize", handleWindowWidthChange);
      handleWindowWidthChange();

      return function cleanup() {
        window.removeEventListener("resize", handleWindowWidthChange);
      };
    },
    [
      isGithaxsAdmin,
      isAdmin,
      history,
      isPermanent,
      theme.breakpoints.values.md,
    ],
  );

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={() => toggleSidebar(layoutDispatch)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
}

export default withRouter(Sidebar);
