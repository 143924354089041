import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Widget from "./Widget";

import GithaxsSDK from "../../../GithaxsSDK";
import useStyles from "../styles";
import { Map, FlashOn, MovieCreation, Backspace } from "@material-ui/icons";

export default function DevArchetypes({ org }) {
  const classes = useStyles();
  const [gotData, setGotData] = useState(false);
  const [terminators, setTerminators] = useState([]);
  const [novelists, setNovelists] = useState([]);
  const [mcQueens, setMcQueens] = useState([]);
  const [doras, setDoras] = useState([]);

  const [tiles, setTiles] = useState(3);

  useEffect(() => {
    async function getDevArchetypes() {
      if (org === "") {
        return;
      }
      const gh = GithaxsSDK.build();

      try {
        let data = await gh.getDevArchetypes(org);

        data = data.filter((u) => u.closed_prs > 10);

        const sortedTerminators = [...data]
          .filter((u) => u.net_changes < 0)
          .sort((a, b) => a.net_changes - b.net_changes);
        const sortedNovelists = [...data].sort(
          (a, b) => b.net_changes - a.net_changes,
        );
        const sortedMcQueens = [...data].sort(
          (a, b) => a.time_to_merge - b.time_to_merge,
        );
        const sortedDoras = [...data].sort(
          (a, b) => b.repos_traveled - a.repos_traveled,
        );

        setTerminators(sortedTerminators);
        setNovelists(sortedNovelists);
        setMcQueens(sortedMcQueens);
        setDoras(sortedDoras);

        if (sortedTerminators.length <= 0) {
          setTiles(4);
        }
        setGotData(true);
      } catch (e) {
        console.log(e);
      }
    }

    getDevArchetypes();
  }, [org]);

  if (!gotData) {
    return "";
  } else {
    return (
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={tiles}>
            <Widget
              title="Blazing PRs"
              upperTitle
              bodyClass={classes.mainChartBody}
              className={classes.card}
              tooltip="Contributors whose PRs are merged the fastest."
              icon={<FlashOn fontSize="large"></FlashOn>}
            >
              <h2>
                1. {mcQueens[0]["user"]} -{" "}
                {Math.round(mcQueens[0]["time_to_merge"])}
              </h2>
              <h4>
                2. {mcQueens[1]["user"]} -{" "}
                {Math.round(mcQueens[1]["time_to_merge"])}
              </h4>
              <h4>
                3. {mcQueens[2]["user"]} -{" "}
                {Math.round(mcQueens[2]["time_to_merge"])}
              </h4>
            </Widget>
          </Grid>
          <Grid item xs={tiles}>
            <Widget
              title="Repos Explored"
              upperTitle
              bodyClass={classes.mainChartBody}
              className={classes.card}
              tooltip="Contributors who contribute to the most repositories."
              icon={<Map fontSize="large"></Map>}
            >
              <h2>
                1. {doras[0]["user"]} - {Math.round(doras[0]["repos_traveled"])}
              </h2>
              <h4>
                2. {doras[1]["user"]} - {Math.round(doras[1]["repos_traveled"])}
              </h4>
              <h4>
                3. {doras[2]["user"]} - {Math.round(doras[2]["repos_traveled"])}
              </h4>
            </Widget>
          </Grid>
          {terminators.length > 0 && (
            <Grid item xs={tiles}>
              <Widget
                title="Code Cleaner"
                upperTitle
                bodyClass={classes.mainChartBody}
                className={classes.card}
                tooltip="Contributors who have the highest net deletions of code."
                icon={<Backspace fontSize="large"></Backspace>}
              >
                <h2>
                  1. {terminators[0]["user"]} -{" "}
                  {Math.round(terminators[0]["net_changes"])}
                </h2>
                <h4>
                  2. {terminators[1]["user"]} -{" "}
                  {Math.round(terminators[1]["net_changes"])}
                </h4>
                <h4>
                  3. {terminators[2]["user"]} -{" "}
                  {Math.round(terminators[2]["net_changes"])}
                </h4>
              </Widget>
            </Grid>
          )}
          <Grid item xs={tiles}>
            <Widget
              title="Code Creator"
              upperTitle
              bodyClass={classes.mainChartBody}
              className={classes.card}
              tooltip="Contributors who have the highest net additions of code."
              icon={<MovieCreation fontSize="large"></MovieCreation>}
            >
              <h2>
                1. {novelists[0]["user"]} -{" "}
                {Math.round(novelists[0]["net_changes"])}
              </h2>
              <h4>
                2. {novelists[1]["user"]} -{" "}
                {Math.round(novelists[1]["net_changes"])}
              </h4>
              <h4>
                3. {novelists[2]["user"]} -{" "}
                {Math.round(novelists[2]["net_changes"])}
              </h4>
            </Widget>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
