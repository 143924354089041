import React, { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { Grid } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers";
import GithaxsSDK from "../../../GithaxsSDK";
import { padPullRequestData } from "../DateFill";
import ApexAppGraph from "../../../components/ApexAppGraph/ApexAppGraph";
import useStyles from "../styles";

export default function TimeToMerge({ org, repo, loading, loadedData }) {
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function get_pr_stats() {
      if (org === "") {
        return;
      }
      const gh = GithaxsSDK.build();
      try {
        const data = await gh.getOrgPullRequestStats(org, repo);

        if (data.length > 0) {
          setData(
            padPullRequestData(
              data.map((d) => ({
                ...d,
                median_time_to_merge: (d.median_time_to_merge / 24).toFixed(2),
              })),
            ),
          );
        }
      } catch (e) {
        if (e?.response?.status !== 403) {
          console.log(e);
        }
      } finally {
        // When the page first loads there is no org set so data is
        // not actually retrieved.
        // If an org is set then we did retrieve the data or the
        // request had an error.
        loadedData(true);
      }
    }
    get_pr_stats();
  }, [org, repo, loadedData]);

  if (loading) {
    return "";
  } else if (data.length < 2) {
    return (
      <Typography variant="h3" align="center">
        Two weeks of data is needed. Check back next week.
      </Typography>
    );
  }

  return (
    <>
      <Grid item xs={6}>
        <Widget
          title="Merge Time (hrs)"
          upperTitle
          bodyClass={classes.mainChartBody}
          className={classes.card}
          disableWidgetMenu={true}
        >
          <ApexAppGraph
            title=""
            series={[
              {
                name: "Average",
                data: data.map((d) => Math.round(d.time_to_merge, 2)),
              },
              {
                name: "Median",
                data: data.map((d) => Math.round(d.median_time_to_merge, 2)),
              },
            ]}
            xaxis_categories={data.map((d) => d.week)}
          ></ApexAppGraph>
        </Widget>
      </Grid>
      <Grid item xs={6}>
        <Widget
          title="Closed PRs"
          upperTitle
          bodyClass={classes.mainChartBody}
          className={classes.card}
          disableWidgetMenu={true}
        >
          <ApexAppGraph
            title=""
            series={[
              { name: "Closed PRs", data: data.map((d) => d.closed_prs) },
            ]}
            xaxis_categories={data.map((d) => d.week)}
          ></ApexAppGraph>
        </Widget>
      </Grid>
    </>
  );
}
