import React from "react";
import ReactApexChart from "react-apexcharts";

export default function ApexColumnChart({ series, dates, xaxis_type = "datetime" }) {
  const state = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      title: {
        text: "",
      },
      xaxis: {
        type: xaxis_type,
        categories: dates,
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
}
