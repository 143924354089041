import React, { useState, useEffect } from "react";
import ApexColumnChart from "../../../components/ApexColumnChart/ApexColumnChart";
import Widget from "../../../components/Widget/Widget";

import GithaxsSDK from "../../../GithaxsSDK";

import useStyles from "../styles";

export default function PRHistogram({ org, repo, loading, type }) {
  const [data, setData] = useState({ dates: [], series: [] });
  const classes = useStyles();

  useEffect(() => {
    async function getPRTimeline() {
      if (org === "") {
        return;
      }

      const gh = GithaxsSDK.build();

      try {
        let data = await gh.getPRHistogram(org, repo, type);

        const series = [];

        data.forEach((repo_data) => {
          let { name } = repo_data;
          data = [
            repo_data.in_one_hour,
            repo_data.one_hour_to_one_day,
            repo_data.one_day_to_three_day,
            repo_data.over_three_days,
          ];

          series.push({ name, data });
        });

        setData({
          dates: ["<1hr", "1hr-1d", "1d-3d", ">3d"],
          series: series,
        });
      } catch (e) {
        console.log(e);
      }
    }

    getPRTimeline();
  }, [org, repo, type]);

  if (loading || data.series.length === 0) {
    return "";
  }
  return (
    <React.Fragment>
      <Widget
        title={`Merge Times by ${type}`}
        upperTitle
        bodyClass={classes.mainChartBody}
        className={classes.card}
        disableWidgetMenu={true}
      >
        <ApexColumnChart
          series={data.series}
          dates={data.dates}
          xaxis_type="category"
        ></ApexColumnChart>
      </Widget>
    </React.Fragment>
  );
}
