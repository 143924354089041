import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";

import GithaxsSDK from "../../GithaxsSDK";
import FinalContext from "./components/FinalContext";
import DefaultContext from "./components/DefaultContext";
import NestedContext from "./components/NestedContext";

function Context(props) {
  const [contexts, setContexts] = useState({});

  useEffect(() => {
    const gh = GithaxsSDK.build(true);

    async function getContexts() {
      let contexts = await gh.getContexts();
      setContexts(contexts);
    }

    getContexts();
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DefaultContext context={contexts.default}></DefaultContext>
      </Grid>
      <NestedContext
        context={contexts.topic}
        contextName="topic"
      ></NestedContext>
      <NestedContext context={contexts.repo} contextName="repo"></NestedContext>
      <NestedContext
        context={contexts.custom}
        contextName="custom"
      ></NestedContext>
      <Grid item xs={12}>
        <FinalContext context={contexts.final}></FinalContext>
      </Grid>
    </Grid>
  );
}

export default Context;
