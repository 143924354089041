import { Modal, Box, Typography, Button } from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import useStyles from "./styles";

function SavedModal(props) {
  const classes = useStyles();
  const { open, handleModalClose } = props;
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.settingsModal}>
        <CheckCircleOutlineOutlinedIcon
          style={{ fontSize: "50px", color: "#03CA9880", marginBottom: "10px" }}
        />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{ marginBottom: "10px" }}
        >
          Settings Updated!
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleModalClose}
        >
          Ok!
        </Button>
      </Box>
    </Modal>
  );
}

export default SavedModal;
