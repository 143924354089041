import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TextField,
} from "@material-ui/core";
import { DeleteOutline, Add } from "@material-ui/icons";

export default function TableComponent({
  data,
  addItem,
  updateItem,
  deleteItem,
}) {
  return (
    <Table className="mb-0">
      <TableHead></TableHead>
      <TableBody>
        {data.map((name, index) => (
          <TableRow key={index}>
            <TableCell className="pl-3 fw-normal">
              <TextField
                margin="dense"
                id="outlined-basic"
                defaultValue={name}
                type="text"
                fullWidth
                onChange={(e) => {
                  updateItem(e.target.value, index);
                }}
                variant="standard"
              />
            </TableCell>
            <TableCell>
              <DeleteOutline onClick={() => deleteItem(name)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <Add onClick={() => addItem()} />
    </Table>
  );
}
