const moment = require("moment");

function dateFill(data) {
  let sortedData = data.sort((a, b) => new Date(a.week) - new Date(b.week));
  let currentDate = sortedData[0].week;

  let paddedResults = [];
  while (moment(currentDate, "YYYY-MM-DD").isSameOrBefore(moment())) {
    paddedResults.push({ week: currentDate });
    currentDate = moment(currentDate, "YYYY-MM-DD")
      .add(7, "d")
      .format("YYYY-MM-DD");
  }

  return paddedResults;
}

function padPullRequestData(data) {
  const defaultData = {
    additions: 0,
    deletions: 0,
    net_additions: 0,
    changed_files: 0,
    comments: 0,
    commits: 0,
    review_comments: 0,
    time_to_merge: 0,
    median_time_to_merge: 0,
    closed_prs: 0,
    repos_changed: 0,
  };

  return padReturnedData(data, defaultData);
}

function padStatusCheckData(data) {
  const defaultData = {
    avg_time_to_complete: 0,
    passed: 0,
    failed: 0,
    errored: 0,
    passed_percent: 0,
    failed_percent: 0,
    erred_percent: 0,
    total: 0,
  };

  return padReturnedData(data, defaultData);
}

function padCheckRunData(data) {
  const defaultData = {
    name: data[0].name,
    passed: 0,
    failed: 0,
    avg_time_to_complete: 0,
  };

  return padReturnedData(data, defaultData);
}

function padReturnedData(data, defaultData) {
  const paddedDates = dateFill(data);

  const paddedResults = [];
  paddedDates.forEach((paddedDate) => {
    const existingData = data.filter((d) => d.week === paddedDate.week);

    const d = { ...defaultData, week: paddedDate.week };

    paddedResults.push(existingData[0] || d);
  });

  return paddedResults;
}

export { dateFill, padCheckRunData, padPullRequestData, padStatusCheckData };
