import React, { useState, useEffect } from "react";
import ApexAppGraph from "../../../components/ApexAppGraph/ApexAppGraph";
import Widget from "../../../components/Widget/Widget";

import GithaxsSDK from "../../../GithaxsSDK";
import useStyles from "../styles";

export default function CodeCoverage({ org, repo }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getPRTimeline() {
      const gh = GithaxsSDK.build(true);

      try {
        let data = await gh.getCodeCoverage(org, repo);
        setData(data);
      } catch (e) {
        console.log(e);
      }
    }

    getPRTimeline();
  }, [org, repo]);

  if (data.length === 0 || repo === "All") {
    return "";
  } else {
    return (
      <Widget
        title="Test Coverage"
        upperTitle
        bodyClass={classes.mainChartBody}
        className={classes.card}
        disableWidgetMenu={true}
      >
        <ApexAppGraph
          title=""
          series={[{ name: "Coverage", data: data.map((d) => Math.round(d.value)) }]}
          xaxis_categories={data.map((d) => d.date)}
        ></ApexAppGraph>
      </Widget>
    );
  }
}
