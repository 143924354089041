import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  loading: {
    margin: "auto",
    width: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "75vh",
  },
  loadingGif: {
    margin: "auto",
    textAlign: "center",
  },
}));
