// styles
import useStyles from "./styles";

export default function Loading({ show }) {
  var classes = useStyles();

  return (
    <div
      className={classes.loading}
      style={{
        display: show ? "flex" : "none",
        alignItems: "center",
        justify: "center",
      }}
    >
      <lottie-player
        src="https://assets7.lottiefiles.com/packages/lf20_bbmwwe1u.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
}
