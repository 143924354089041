import React from "react";

export default function AccountManagement() {
  return (
    <>
      <iframe
        src="https://checkout.billsby.com/?data-billsby-company=githaxs&amp;data-billsby-type=account&amp;billsbyData=%7B%7D"
        title="billsby-account-mgmt"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </>
  );
}
