import React, { useState } from "react";
import { Grid, Typography, Button, Tabs, Tab } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "axios";

// styles
import useStyles from "./styles";

// logo
import logo from "./GHX_logo.svg";
import github from "../../images/github.svg";

// settings
import settings from "../../settings";

function Login(props) {
  var classes = useStyles();

  // local
  var [activeTabId, setActiveTabId] = useState(0);

  function login() {
    // TODO: add error handling
    axios
      .get(`${settings.API_ENDPOINT}/auth/login`)
      .then((resp) => {
        window.location = resp.data.url;
      })
      .catch(() => {
        props.history.push("/login");
      });
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Button
                size="large"
                className={classes.googleButton}
                onClick={login}
              >
                <img src={github} alt="github" className={classes.googleIcon} />
                &nbsp;Sign in with GitHub
              </Button>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2018-{new Date().getFullYear()}{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="https://githaxs.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Githaxs
          </a>
          , LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
