import axios from "axios";
import settings from "./settings";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      localStorage.removeItem("user_avatar");
      localStorage.removeItem("user_orgs");
      localStorage.removeItem("current_org");
      window.location.href = "/login";
    }
    throw error;
  },
);

class GithaxsSDK {
  constructor() {
    this.endpoint = settings.API_ENDPOINT;
    this.jwt = localStorage.getItem("jwt");
    this.headers = { "X-Token": this.jwt };
  }

  static build() {
    return new GithaxsSDK();
  }

  async listRepos(orgName) {
    if (orgName) {
      const url = `insights/repos?org_name=${orgName}`;

      const response = await this.get(url);
      const { data } = response;

      return data;
    }
  }

  async getOrgPullRequestStats(orgName, repoName) {
    if (orgName) {
      let url = `insights/pr_stats?org_name=${orgName}`;
      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getCheckResultStats(orgName, repoName) {
    if (orgName) {
      let url = `insights/check_result_stats?org_name=${orgName}`;

      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getStatusInsights(orgName, repoName) {
    if (orgName) {
      let url = `insights/status_check_stats?org_name=${orgName}`;

      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getHalsteadMetrics(orgName, repoName) {
    if (orgName) {
      let url = `insights/cc?org_name=${orgName}&repo_name=${repoName}`;

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getCoverage(orgName, repoName) {
    if (orgName) {
      let url = `insights/coverage?org_name=${orgName}&repo_name=${repoName}`;

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getFileChanges(orgName, repoName) {
    if (orgName) {
      let url = `insights/file_changes?org_name=${orgName}&repo_name=${repoName}`;

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getPRTimeline(orgName, repoName = "All") {
    if (orgName) {
      let url = `insights/pr_timeline?org_name=${orgName}`;

      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }
      const response = await this.get(url);
      return response?.data;
    }
  }

  async getPRHistogram(orgName, repoName = "All", type) {
    if (orgName) {
      let url = `insights/pr_histogram?org_name=${orgName}&type=${type}`;

      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }
      const response = await this.get(url);
      return response?.data;
    }
  }

  async getCodeComplexity(orgName, repoName = "All") {
    if (orgName) {
      let url = `insights/code_complexity?org_name=${orgName}`;

      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }
      const response = await this.get(url);
      return response?.data;
    }
  }

  async getDevArchetypes(orgName) {
    if (orgName) {
      let url = `insights/archetypes?org_name=${orgName}`;

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getPRLog(orgName) {
    if (orgName) {
      let url = `insights/pr_log?org_name=${orgName}`;

      const response = await this.get(url);
      return response?.data;
    }
  }

  async getCodeCoverage(orgName, repoName = "All") {
    if (orgName) {
      let url = `insights/code_coverage?org_name=${orgName}`;

      if (repoName !== "All") {
        url += `&repo_name=${repoName}`;
      }
      const response = await this.get(url);
      return response?.data;
    }
  }

  async getContexts(orgName) {
    let url = `contexts/${orgName}`;

    const response = await this.get(url);
    return response?.data;
  }

  async getReleases(orgName, repo = "All") {
    let url = `insights/releases?org_name=${orgName}`;

    if (repo !== "All") {
      url += `&repo=${repo}`;
    }

    const response = await this.get(url);
    return response?.data;
  }

  get(path) {
    return axios.get(`${this.endpoint}/${path}`, { headers: this.headers });
  }

  post(path, body) {
    return axios.post(`${this.endpoint}/${path}`, body, {
      headers: this.headers,
    });
  }
}

export default GithaxsSDK;
