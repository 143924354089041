import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Flags } from "react-feature-flags";

import Loading from "../../components/Loading/Loading";
import { Typography } from "../../components/Wrappers";
import AppDown from "../../components/AppDown/AppDown";

import TimeToMerge from "./components/TimeToMerge";
import PRTimelineChart from "./components/PRTimeLine";
import CodeComplexity from "./components/CodeComplexity";
import CodeCoverage from "./components/CodeCoverage";
import Releases from "./components/Releases";
import PRHistogram from "./components/PRHistogram";
import Analytics from "./components/Analytics";

import GithaxsSDK from "../../GithaxsSDK";
import { getCurrentOrg } from "../../utils/functions";

export default function Dashboard(props) {
  const org = new URLSearchParams(props.history.location.search).get("org");
  const repo = new URLSearchParams(props.history.location.search).get("repo") || "All";
  const fullOrg = getCurrentOrg(org);

  const [hasInsights, setHasInsights] = useState(true);

  const [selectedOrg, setSelectedOrg] = useState(org);

  var [loading, setLoading] = useState(props.location.pathname !== "/demo");

  const [repoValue, setRepoValue] = useState(repo);
  const [repoOptions, setRepoOptions] = useState(["All"]);

  // Variables used to determine if the loading icon should be shown
  // Once all three of these variables are true the loading icon should
  // disappear.
  const [retrievedPullRequestData, setRetrievedPullRequestData] =
    useState(false);
  const [retrievedRepoNames, setRetrievedRepoNames] = useState(false);

  // Update demo value if user navigates to a new page
  // If the user starts on the demo page but then
  // logs in and navigates to the dashboard
  // they should not see demo data anymore.
  useEffect(() => {
    if (props.location.pathname === "/demo") {
      setSelectedOrg("demo");
    }
  }, [props.location.pathname]);

  useEffect(() => {
    setHasInsights(fullOrg.subscription_level >= 2 || org === "demo");
  }, [fullOrg.subscription_level, org]);

  // Update loading icon as we retrieve data
  useEffect(() => {
    let isLoading = !(retrievedRepoNames && retrievedPullRequestData);
    setLoading(isLoading);
  }, [retrievedPullRequestData, retrievedRepoNames]);

  useEffect(() => {
    async function get_repos() {
      const gh = GithaxsSDK.build();
      try {
        let data = await gh.listRepos(selectedOrg);
        const NewRepoOptions = ["All", ...data.sort()];
        setRepoOptions(NewRepoOptions);
      } catch (e) {
        if (e?.response?.status !== 403) {
          console.log(e);
        }
      } finally {
        // When the page first loads there is no org set so data is
        // not actually retrieved.
        // If an org is set then we did retrieve the data or the
        // request had an error.
        setRetrievedRepoNames(selectedOrg !== "");
      }
    }

    hasInsights && selectedOrg !== "" && get_repos();
  }, [selectedOrg, props.history, hasInsights]);

  function handleRepoChange(value) {
    props.history.push({
      pathname: '/app/insights',
      search: `?org=${org}&repo=${value}`
    })
    setRepoValue(value);
    setRetrievedPullRequestData(false);
  }

  if (!hasInsights) {
    return (
      <Typography variant="h3" align="center">
        Please upgrade your subscription to access insights.
      </Typography>
    );
  } else {
    return (
      <>
        <Loading show={loading} />
        {!loading && <Typography variant="h1">Velocity</Typography>}
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-end"
        >
          {!loading && (
            <AppDown
              value={repoValue}
              change={handleRepoChange}
              options={repoOptions}
            />
          )}
        </Grid>
        <Grid container spacing={4}>
          <>
            <TimeToMerge
              org={selectedOrg}
              repo={repoValue}
              loadedData={setRetrievedPullRequestData}
              loading={loading}
            ></TimeToMerge>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <PRTimelineChart
                org={selectedOrg}
                repo={repoValue}
                loading={loading}
              ></PRTimelineChart>
            </Grid>
            <Grid item xs={6}>
              <PRHistogram
                org={selectedOrg}
                repo={repoValue}
                loading={loading}
                type="repo"
              ></PRHistogram>
            </Grid>
            <Grid item xs={6}>
              <PRHistogram
                org={selectedOrg}
                repo={repoValue}
                loading={loading}
                type="user"
              ></PRHistogram>
            </Grid>
            <Grid item xs={12}>
              <Releases
                org={selectedOrg}
                repo={repoValue}
                loading={loading}
              ></Releases>
            </Grid>
            <Grid item xs={12}>
            {!loading && <Typography variant="h1">Quality</Typography>}
            </Grid>
            <Flags authorizedFlags={["complexity"]}>
              <Grid item xs={6}>
                <CodeComplexity
                  org={selectedOrg}
                  repo={repoValue}
                  loading={loading}
                ></CodeComplexity>
              </Grid>
            </Flags>
            <Flags authorizedFlags={["coverage"]}>
              <Grid item xs={6}>
                <CodeCoverage
                  org={selectedOrg}
                  repo={repoValue}
                  loading={loading}
                ></CodeCoverage>
              </Grid>
            </Flags>
            <Grid item xs={12}>
              <Analytics
                org={selectedOrg}
                repo={repoValue}
                loading={loading}
              ></Analytics>
            </Grid>
          </>
        </Grid>
      </>
    );
  }
}
