class InstallationCategory {
  constructor(values = []) {
    this.values = new Set(values);
  }

  addInstallation(value) {
    this.values.add(value);
  }

  updateInstallation(oldValue, newValue) {
    this.values.delete(oldValue);
    this.values.add(newValue);
  }

  removeInstallation(value) {
    this.values.delete(value);
  }

  toJSON() {
    return Array.from(this.values);
  }
}

class TaskConfig {
  constructor(name) {
    this.name = name;
    this.settings = {
      org: {
        default: {},
        final: {},
      },
      repos: [],
      repo_topics: [],
    };
    this.installation = {
      repo_languages: new InstallationCategory(),
      repo_topics: new InstallationCategory(),
      repo_names: new InstallationCategory(),
      org: false,
    };
  }

  clone() {
    let tc = new TaskConfig(this.name);
    tc.settings = this.settings;
    tc.installation = this.installation;

    return tc;
  }

  static fromJson(obj) {
    const tc = new TaskConfig(obj.name);
    tc.setOrgInstallation(obj.installation.org);
    obj.installation.repo_languages &&
      obj.installation.repo_languages.forEach((l) =>
        tc.installation.repo_languages.addInstallation(l),
      );
    obj.installation.repo_names &&
      obj.installation.repo_names.forEach((n) =>
        tc.installation.repo_names.addInstallation(n),
      );
    obj.installation.repo_topics &&
      obj.installation.repo_topics.forEach((n) =>
        tc.installation.repo_topics.addInstallation(n),
      );
    Object.assign(tc.settings, obj.settings);

    return tc;
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }

  setOrgInstallation(value) {
    this.installation.org = !!value;
  }
}

export default TaskConfig;
