import React, { useState, useEffect } from "react";

import TaskTable from "./components/TaskTable/TaskTable";
import DevArchetypes from "./components/DevArchetypes";
import GithaxsSDK from "../../GithaxsSDK";

export default function PRLog(props) {
  const org = new URLSearchParams(props.history.location.search).get("org");

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function getPRLog() {
      const gh = GithaxsSDK.build();
      const data = await gh.getPRLog(org);
      setTableData(data);
    }
    getPRLog();
  }, []);

  const columns = [
    {
      name: "user",
      label: "User",
      options: {
        filter: true,
      },
    },
    {
      name: "repo_name",
      label: "Repo",
      options: {
        filter: true,
      },
    },
    {
      name: "pr_number",
      label: "PR Number",
      options: {
        filter: false,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
      },
    },
    {
      name: "changed_files",
      label: "# Changed Files",
      options: {
        filter: false,
      },
    },
    {
      name: "time_to_merge",
      label: "Time to Merge (hrs)",
      options: {
        filter: false,
      },
    },
    {
      name: "additions",
      label: "Additions",
      options: {
        filter: false,
      },
    },
    {
      name: "deletions",
      label: "Deletions",
      options: {
        filter: false,
      },
    },
  ];

  return (
    <>
      <DevArchetypes org={org}></DevArchetypes>
      <TaskTable tableData={tableData} columns={columns} />
    </>
  );
}
