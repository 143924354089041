import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "16px 40px",
  },
  headerFont: {
    fontSize: "1.5rem",
    color: "#222",
    margin: "0px 0px 0.75rem",
  },
  otherFont: {
    color: "#222",
    fontSize: "1.25rem",
  },
  boxShadow: {
    margin: "40px 0",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 12px 0px",
    border: "1px solid rgb(227, 227, 227)",
    borderRadius: "4px",
  },
  settingsModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
}));
