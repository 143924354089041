import React, { useState, useEffect } from "react";
import ApexAppGraph from "../../../components/ApexAppGraph/ApexAppGraph";
import Widget from "../../../components/Widget/Widget";

import GithaxsSDK from "../../../GithaxsSDK";
import useStyles from "../styles";

export default function Releases({ org, repo, loading }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (org === "") {
      return;
    }

    async function getPRTimeline() {
      const gh = GithaxsSDK.build();

      try {
        let data = await gh.getReleases(org, repo);
        setData(data);
      } catch (e) {
        console.log(e);
      }
    }

    getPRTimeline();
  }, [org, repo]);

  if (data.length === 0 || loading) {
    return "";
  } else {
    return (
      <Widget
        title="Tagged Releases"
        upperTitle
        bodyClass={classes.mainChartBody}
        className={classes.card}
        disableWidgetMenu={true}
      >
        <ApexAppGraph
          title=""
          series={[{ name: "Releases", data: data.map((d) => d.releases) }]}
          xaxis_categories={data.map((d) => d.week)}
        ></ApexAppGraph>
      </Widget>
    );
  }
}
