import React, { useState, useEffect } from "react";
import ApexColumnChart from "../../../components/ApexColumnChart/ApexColumnChart";
import Widget from "../../../components/Widget/Widget";

import GithaxsSDK from "../../../GithaxsSDK";

import useStyles from "../styles";

export default function PRTimeLine({ org, repo, loading }) {
  const [data, setData] = useState({ dates: [], series: [] });
  const classes = useStyles();

  useEffect(() => {
    async function getPRTimeline() {
      if (org === "") {
        return;
      }

      const gh = GithaxsSDK.build();

      try {
        let data = await gh.getPRTimeline(org, repo);

        const timesToFirstReview = {
          name: "First Review (hrs)",
          data: data.map((d) => Math.round(d.avg_time_to_first_review / 60)),
        };
        const timesToLastReview = {
          name: "Last Review (hrs)",
          data: data.map((d) => Math.round(d.avg_time_to_last_review / 60)),
        };

        const timesToMerge = {
          name: "Merge (hrs)",
          data: data.map((d) => Math.round(d.avg_time_to_merge / 60)),
        };
        setData({
          dates: data.map((d) => d.week),
          series: [timesToFirstReview, timesToLastReview, timesToMerge],
        });
      } catch (e) {
        console.log(e);
      }
    }

    getPRTimeline();
  }, [org, repo]);

  if (loading) {
    return "";
  }
  return (
    <React.Fragment>
      <Widget
        title="PR Timeline"
        upperTitle
        bodyClass={classes.mainChartBody}
        className={classes.card}
        disableWidgetMenu={true}
      >
        <ApexColumnChart
          series={data.series}
          dates={data.dates}
        ></ApexColumnChart>
      </Widget>
    </React.Fragment>
  );
}
