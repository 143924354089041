import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import axios from "axios";
import { useUserDispatch, loginUser } from "../../context/UserContext";

import Loading from "../Loading/Loading";

import settings from "../../settings";

function Auth(props) {
  var userDispatch = useUserDispatch();

  var params = new URLSearchParams(useLocation().search);
  var code = params.get("code");
  var state = params.get("state");

  axios
    .post(`${settings.API_ENDPOINT}/auth/authorize`, { state, code })
    .then((resp) => {
      loginUser(
        userDispatch,
        resp.data.jwt,
        resp.data.user,
        resp.data.user_orgs,
        props.history,
      );
    });

  return <Loading show={true} />;
}

export default withRouter(Auth);
