let ENV = localStorage.getItem("ENV") || "prod";
let API_ENDPOINTS = {
  local: "https://54os42pm11.execute-api.us-west-2.amazonaws.com/Prod",
  staging: "https://54os42pm11.execute-api.us-west-2.amazonaws.com/Prod",
  prod: "https://3m3y7vuil0.execute-api.us-west-2.amazonaws.com/Prod",
};
let API_ENDPOINT = API_ENDPOINTS[ENV];

const FEATURE_FLAGS = [
  { name: "prTimeline", isActive: true },
  { name: "coverage", isActive: true },
  { name: "complexity", isActive: true },
];

let settings = {
  ENV,
  API_ENDPOINT,
  FEATURE_FLAGS,
};

export default settings;
