export const getColor = (index) => {
  if (index === 0) {
    return "primary";
  } else if (index === 1) {
    return "warning";
  } else if (index === 2) {
    return "error";
  } else if (index === 3) {
    return "success";
  } else {
    return "info";
  }
};

export const getCurrentOrg = (orgName) => {
  const allOrgs = JSON.parse(localStorage.getItem("user_orgs"));
  const currentOrg = allOrgs.filter((o) => o.login === orgName);
  return currentOrg[0];
};
