import { Grid, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";

function TaskTable({ tableData, columns, save, isUserAdmin }) {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="Tasks"
            data={tableData}
            columns={columns}
            options={{
              filterType: "checkbox",
              download: false,
              print: false,
              selectableRows: "none",
              selectableRowsHideCheckboxes: true,
              selectableRowsOnClick: true,
              selectToolbarPlacement: "none",
            }}
          />
        </Grid>
        <Button
          className={classes.save}
          variant="contained"
          color="secondary"
          onClick={() => save()}
          disabled={!isUserAdmin}
        >
          Save
        </Button>
      </Grid>
    </div>
  );
}

export default TaskTable;
