import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from "@material-ui/core";
import Icon from "@mdi/react";

//icons
import { mdiTwitter as TwitterIcon, mdiGithub as GithubIcon } from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Tasks from "../../pages/tasks/Tasks";
import Task from "../../pages/task";
import AccountManagement from "../../pages/account_mgmt/AccountManagement";
import Context from "../../pages/contexts/Context";
import PRLog from "../../pages/pr_log/PRLog";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/demo" component={Dashboard} />
            <Route path="/app/insights" component={Dashboard} />
            <Route path="/app/tasks/:task" component={Task} />
            <Route path="/app/tasks" component={Tasks} />
            <Route path="/app/account" component={AccountManagement} />
            <Route path="/app/contexts" component={Context} />
            <Route path="/app/prlog" component={PRLog} />
          </Switch>
          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justify="space-between"
          >
            <div>
              <Link
                color={"primary"}
                href={"https://githaxs.com/"}
                target={"_blank"}
                className={classes.link}
              >
                Githaxs
              </Link>
              <Link
                color={"primary"}
                href={"https://githaxs.com/about"}
                target={"_blank"}
                className={classes.link}
              >
                About Us
              </Link>
              <Link
                color={"primary"}
                href={"https://githaxs.medium.com/"}
                target={"_blank"}
                className={classes.link}
              >
                Blog
              </Link>
            </div>
            <div>
              <Link href={"https://twitter.com/githaxs"} target={"_blank"}>
                <IconButton aria-label="twitter">
                  <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://github.com/githaxs"} target={"_blank"}>
                <IconButton aria-label="github" style={{ marginRight: -12 }}>
                  <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
            </div>
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
